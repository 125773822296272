import React from "react"

import styles from "./detail-pricing.module.scss"
import DetailPricingSelectorComponent from "./detail-pricing-selector"
import DetailPricingCardsComponent from "./detail-pricing-cards"
import { withLanguage } from "../../../../i18n/with-language"
import ChoosePlanOverlayComponent from "../../../overlays/choose-plan"
import ChoosePlanOverlayService from "../../../../services/choose-plan-overlay-service"

class DetailPricingComponent extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      period: "monthly",
      currency: props.currency || "ru",
      choosePlanIsShow: false,
      choosePlanTier: 'maxi'
    }
  }

  setChoosePlanIsShow = (value) => {
    this.setState({
      choosePlanIsShow: value
    })
  }

  componentDidMount() {
    ChoosePlanOverlayService.addListener(this.setChoosePlanIsShow)
  }

  componentWillUnmount() {
    ChoosePlanOverlayService.removeListener(this.setChoosePlanIsShow)
  }

  onPeriodChange = (period) => {
    this.setState({ period })
  }

  onCurrencyChange = (currency) => {
    this.setState({ currency })
  }

  onChoosePlanClick = (tier) => {
    this.setState({
      choosePlanTier: tier
    })
    ChoosePlanOverlayService.open()
  }

  render() {
    const { period, currency } = this.state
    const { title, className, lang, withOutPrice, integration, mobileMenu } = this.props
    const prices = {
      ru: {
        monthly: {
          mini: '945 ₽',
          opti: '2 000 ₽',
          maxy: '2 450 ₽',
          franchise: '2900 ₽'
        },
        quarterly: {
          mini: '897 ₽',
          opti: '1 900 ₽',
          maxy: '2 333 ₽',
          franchise: '2 900 ₽'
        },
        halfYear: {
          mini: '850 ₽',
          opti: '1 800 ₽',
          maxy: '2 200 ₽',
          franchise: '2 900 ₽'
        },
        yearly: {
          mini: '802 ₽',
          opti: '1 700 ₽',
          maxy: '2 075 ₽',
          franchise: '2 900 ₽'
        }
      }
      //ua: {
      //  monthly: {
      //    mini: '329 ₴',
      //    opti: '694 ₴',
      //    maxy: '895 ₴',
      //    franchise: '1 060 ₴'
      //  },
      //  quarterly: {
      //    mini: `317 ₴`,
      //    opti: `658 ₴`,
      //    maxy: `853 ₴`,
      //    franchise: '1 060 ₴'
      //  },
      //  halfYear: {
      //    mini: `299 ₴`,
      //    opti: `628 ₴`,
      //    maxy: `804 ₴`,
      //    franchise: '1060 ₴'
      //  },
      //  yearly: {
      //    mini: `280 ₴`,
      //    opti: `591 ₴`,
      //    maxy: `758 ₴`,
      //    franchise: '1 060 ₴'
      //  }
      //},
      //kz: {
      //  monthly: {
      //    mini: '5 169 ₸',
      //    opti: '10 913 ₸',
      //    maxy: '14 072 ₸',
      //    franchise: '16 656 ₸'
      //  },
      //  quarterly: {
      //    mini: `4 980 ₸`,
      //    opti: `10 338 ₸`,
      //    maxy: `13 400 ₸`,
      //    franchise: '16 656 ₸'
      //  },
      //  halfYear: {
      //    mini: `4 693 ₸`,
      //    opti: `9 862 ₸`,
      //    maxy: `12 636 ₸`,
      //    franchise: '16 656 ₸'
      //  },
      //  yearly: {
      //    mini: `4 405 ₸`,
      //    opti: `9 287 ₸`,
      //    maxy: `11 918 ₸`,
      //    franchise: '16 656 ₸'
      //  }
      //}
    };
    return <div id='pricing' className={`${styles.pricing} ${className || ""}`}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <DetailPricingSelectorComponent
        lang={lang}
        currency={currency}
        onPeriodChange={this.onPeriodChange}
        onCurrencyChange={this.onCurrencyChange}
        withOutPrice={this.props.withOutPrice}
        integration={this.props.integration}
      />
      <div className={styles.detailPricingContainer}>
        <DetailPricingCardsComponent
          prices={prices[currency][period]}
          period={period}
          currency={currency}
          onChoosePlanClick={this.onChoosePlanClick}
          mainPage={this.props.mainPage}
          withintegrationOutPrice={this.props.withOutPrice}
          integration={this.props.integration}
          mobileMenu={mobileMenu}
        />
        <p className={styles.prompt}>При покупке для использования за пределами РФ может включать дополнительные налоги страны лицензиата.</p>
      </div>
      <ChoosePlanOverlayComponent
        show={this.state.choosePlanIsShow}
        tier={this.state.choosePlanTier}
      />
    </div>
  }

}

export default withLanguage(DetailPricingComponent)