import { OverlayService } from "./overlay-service"

class ChoosePlanOverlayService extends OverlayService {

  open(from, defaultMessage) {
    this.defaultMessage = defaultMessage
    super.open(from)
  }

  close() {
    this.defaultMessage = null
    super.close()
  }

}

export default new ChoosePlanOverlayService()
