import React from "react"
import Select from "react-select"

const styles = {
  singleValue(provided, state) {
    return {
      ...provided,
      fontSize: "16px"
    }
  },
  option(provided, state) {
    return {
      ...provided,
      fontSize: "16px",
      backgroundColor: state.isDisabled
        ? null
        : state.isSelected
        ? 'rgba(49,157,237, 0.3)'
        : state.isFocused
        ? 'rgba(49,157,237, 0.3)'
        : null,
      color: state.isDisabled
        ? '#ccc'
        : state.isSelected
        ? '#000'
        : '#000',
    }
  },
  indicatorSeparator(provided, state) {
    return {
      ...provided,
      display: "none"
    }
  },
  control(provided, state) {
    return {
      ...provided,
      borderRadius: "0px",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      boxShadow: state.isFocused ? "0 2px 0 #319DED": "none",
      borderBottomColor: state.isFocused ? "#319DED": "none",
    }
  },
  valueContainer(provided, state) {
    return {
      ...provided,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};

const components = {
  DropdownIndicator() {
    return <div>
      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L8 8L15 1" stroke="#4C4F5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  }
};

export default ({options, value, onChange}) => {
  return <Select
    options={options}
    onChange={onChange}
    value={value}
    components={components}
    styles={styles}
  />
}