import React from "react"
import { Animated } from "react-animated-css"
import CurrencySelectorComponent from "../../../controls/currency-selector"

import styles from "./detail-pricing-selector.module.scss"
import Translate from "../../../../i18n/Translate"
import flagRu from "../../../../images/lang/ru.svg"
import flagUa from "../../../../images/lang/ua.svg"
import flagKz from "../../../../images/lang/kz.svg"

class DetailPricingSelectorComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = { activeItem: "monthly", showCurrencySelector: false }
    this.onPeriodChange = props.onPeriodChange
    this.onCurrencyChange = props.onCurrencyChange
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.currency !== this.props.currency) {
      this.setState({ currency: nextProps.currency })
    }
  }

  getItemActiveClass(item) {
    return item === this.state.activeItem ? styles.activeItem : ""
  }

  isSelected(item) {
    return item === this.state.activeItem
  }

  selectItem(activeItem) {
    this.setState({ activeItem })
    if (this.onPeriodChange) {
      this.onPeriodChange(activeItem)
    }
  }

  selectCurrency(currency) {
    this.setState({ currency })
    if (this.onCurrencyChange) {
      this.onCurrencyChange(currency)
    }
  }



  toggleCurrencySelector() {
    this.setState(state => ({ showCurrencySelector: !state.showCurrencySelector }))
  }

  render() {
    const { currency, withOutPrice } = this.props
    return (
      <>
        <div className={styles.container}>
          <div className={`${styles.pricing_selector_wrapper} ${withOutPrice === true ? styles.withOutPrice : ""}`}>
            <div className={styles.pricing_selector}>
              <div className={`${styles.itemWrapper} ${this.getItemActiveClass("monthly")}`}>
                <div onClick={() => this.selectItem("monthly")}
                     className={styles.item}>
                  <Translate id='detailPricing.monthlyTitle' />
                </div>
              </div>
              <div className={`${styles.itemWrapper} ${this.getItemActiveClass("quarterly")}`}>
                <div onClick={() => this.selectItem("quarterly")}
                     className={styles.item}>
                  <Translate id='detailPricing.quarterlyTitle' />
                </div>
              </div>
              <div className={`${styles.itemWrapper} ${this.getItemActiveClass("halfYear")}`}>
                <div onClick={() => this.selectItem("halfYear")}
                     className={styles.item}>
                  <Translate id='detailPricing.halfYearTitle' />
                </div>
              </div>
              <div className={`${styles.itemWrapper} ${this.getItemActiveClass("yearly")}`}>
                <div onClick={() => this.selectItem("yearly")}
                  className={`${styles.item}`}>
                  <Translate id='detailPricing.yearlyTitle' />
                </div>
              </div>
              <div className={styles.slider} />
            </div>
            {
              this.props.lang === "ru" && <CurrencySelectorComponent
                currency={this.props.currency}
                onChange={(currency) => {this.selectCurrency(currency)}}
              />
            }
          </div>
          <div className={`${styles.pricing_selector_mobile}  ${withOutPrice === true ? styles.withOutPrice : ""}`}>
            <Translate>
              {(translate) => <>
                <select value={this.state.activeItem} className={`${styles.select} ${styles.select_period}`}
                  onChange={event => this.selectItem(event.target.value)}>
                  <option value={"yearly"}>{translate("pricing.yearlyTitle")}</option>
                  <option value={"quarterly"}>{translate("pricing.quarterlyTitle")}</option>
                  <option value={"halfYear"}>{translate("detailPricing.halfYearTitle")}</option>
                  <option value={"monthly"}>{translate("pricing.monthlyTitle")}</option>
                </select>
              </>}
            </Translate>
            {
              this.props.lang === "ru" && (
                <select value={this.props.currency} className={`${styles.select} ${styles.select_currency}`}
                  onChange={event => this.selectCurrency(event.target.value)}>
                  <option value={"ru"}>₽</option>
                  <option value={"ua"}>₴</option>
                  <option value={"kz"}>₸</option>
                </select>
              )
            }
          </div>
        </div>
      </>
    )
  }

}

export default DetailPricingSelectorComponent
