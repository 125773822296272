import React from "react"

import styles from "./detail-pricing-cards.module.scss"
import DetailPricingCardDesctop from "./detail-pricing-card-desctop.js"
import DetailPricingCardMobile from "./detail-pricing-card-mobile.js"



const DetailPricingCardsComponent = ({ prices, onChoosePlanClick, mainPage, withOutPrice, integration, mobileMenu }) => (
  <div className={styles.detailPrice__cards}>
    <DetailPricingCardDesctop prices={prices} mainPage={mainPage} onChoosePlanClick={onChoosePlanClick} withOutPrice={withOutPrice} integration={integration}/>
    <DetailPricingCardMobile prices={prices} mainPage={mainPage} onChoosePlanClick={onChoosePlanClick} withOutPrice={withOutPrice} mobileMenu={mobileMenu} />
  </div>
)

export default DetailPricingCardsComponent
