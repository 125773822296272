import React, { useState, useEffect } from "react"
import BaseOverlayComponent from "../base-overlay"
import ChoosePlanOverlayService from "../../../services/choose-plan-overlay-service"
import Checkbox from "../../controls/checkbox"
import Select from "../../controls/select"
import style from "./choose-plan.module.scss"
import { openOverlay } from "../../../utils/overlay"

export default ({ show, tier }) => {
  let checkboxesAvaliable
  switch (tier) {
    case 'mini':
      console.log(tier, 'mini')
      checkboxesAvaliable = {
        disable: true,
        checked: false
      };
      break;
    case 'opti':
      console.log(tier, 'opti')
      checkboxesAvaliable = {
        disable: true,
        checked: false
      };
      break;
    default:
      console.log(tier, 'default')
      checkboxesAvaliable = {
        disable: true,
        checked: true
      }
  }

  const [studentsDasboards, setStudentsDashboards] = useState(checkboxesAvaliable.checked)
  const [ipPhones, setIpPhones] = useState(checkboxesAvaliable.checked)
  const [branding, setBranding] = useState(false)
  const [tierPeriod, setTierPeriod] = useState('monthly')
  const [studentsDasboardsPeriod, setStudentsDashboarPeriod] = useState('monthly')
  const [ipPhonesPeriod, setIpPhonesPeriod] = useState('monthly')
  useEffect(() => {
    setIpPhones(checkboxesAvaliable.checked)
    setStudentsDashboards(checkboxesAvaliable.checked)
  }, [tier, checkboxesAvaliable.checked])

  const title = {
    mini: 'Мини',
    opti: 'Опти',
    maxy: 'Макси',
    franchise: 'Франшизы'
  }
  const periodNames = {
    monthly: '1 месяц',
    quarterly: '3 месяца',
    halfYear: '6 месяцев',
    yearly: '1 год'
  }
  function createLink() {
    const link = 'https://pay.alfabank.ru/payment/constructor/prepay.html'
    const enc = (json) => encodeURI(JSON.stringify(json))
    const params = [
      'login=paraplancrm',
      'logo=1',
      `def=${enc({
        name: "amount",
        value: `${summary()}`,
        title: `Тариф "${title[tier]}" - ${periodNames[tierPeriod]}`
      })}`,
      `ask=${enc({
        name: "Личные кабинеты клиентов",
        value: `${studentsDasboards ? periodNames[studentsDasboardsPeriod]: 'Нет'}`,
        state: 0
      })}`,
      `ask=${enc({
        name: "ip телефония",
        value: `${ipPhones ? periodNames[ipPhonesPeriod]: 'Нет'}`,
        state: 0
      })}`,
      // `ask=${enc({
      //   name: "Брендинг",
      //   value: `${branding ? 'Да': 'Нет'}`,
      //   state: 0
      // })}`,
      `def=${enc({
        name: "orderNumber",
        value: title[tier],
        title: "Тариф",
        state: 1
      })}`,
      `def=${enc({
        name: "description",
        value: periodNames[tierPeriod],
        title: "Период",
        state: 1
      })}`,
      `ask=${enc({
        name: "Номер аккаунта",
        tip: "Номер аккаунта клиента можно найти в настройках",
        state: 2
      })}`
    ]
    return `${link}?${params.join('&')}`
  }

  function findPeriod(periodList, period) {
    return periodList.find(item => item.value === period)
  }

  function onClick() {
    window.location.href = createLink()
  }

  const prices = {
    monthly: {
      mini: '945',
      opti: '2000',
      maxy: '2450',
      franchise: '2900'
    },
    quarterly: {
      mini: '2690',
      opti: '5700',
      maxy: '7000',
      franchise: '2900'
    },
    halfYear: {
      mini: '5100',
      opti: '10800',
      maxy: '13200',
      franchise: '2900'
    },
    yearly: {
      mini: '9630',
      opti: '20400',
      maxy: '24900',
      franchise: '2900'
    }
  }

  const periodTierOptions = [
    { value: 'monthly', label: '1 месяц' },
    { value: 'quarterly', label: <span>3 месяца <span className={style.accent}>(выгода 5%)</span></span> },
    { value: 'halfYear', label: <span>6 месяцев <span className={style.accent}>(выгода 10%)</span></span> },
    { value: 'yearly', label: <span>1 год <span className={style.accent}>(выгода 15%)</span></span> }
  ]

  const periodOptions = [
    { value: 'monthly', label: '1 месяц' },
    { value: 'quarterly', label: '3 месяца' },
    { value: 'halfYear', label: '6 месяцев' },
    { value: 'yearly', label: '1 год' }
  ]


  const periodDelimeters = {
    monthly: 1,
    quarterly: 3,
    halfYear: 6,
    yearly: 12
  }

  function monthPay() {
    const delimeter = periodDelimeters[tierPeriod]
    return summary()/delimeter
  }

  function summary() {
    return parseInt(prices[tierPeriod][tier]) + extraOptionsPay()
  }

  function extraOptionsPay() {
    const studentsDashboardsCoast = 400
    const ipPhonesCoast = 400
    const brandingCoast = 5000
    const studentsDashboardsSumm = studentsDasboards && !checkboxesAvaliable.disable ? periodDelimeters[studentsDasboardsPeriod] * studentsDashboardsCoast : 0
    const ipPhonesSumm = ipPhones && !checkboxesAvaliable.disable ? periodDelimeters[ipPhonesPeriod] * ipPhonesCoast : 0
    const brandingSumm = branding && !checkboxesAvaliable.disable ? brandingCoast : 0
    return studentsDashboardsSumm + ipPhonesSumm + brandingSumm
  }

  function discount() {
    return (prices['monthly'][tier] * periodDelimeters[tierPeriod] + extraOptionsPay()) - summary()
  }

  return (
    <BaseOverlayComponent
      choosePlan={true}
      show={show}
      title={<h2 className={style.title}>Тариф: <span className={style.accent}>{title[tier]}</span></h2>}
      controller={ChoosePlanOverlayService}
    >
      <div className={style.position}>
        Длительность подписки
        <Select
          value={findPeriod(periodTierOptions, tierPeriod)}
          options={periodTierOptions}
          onChange={(e) => {
            setStudentsDashboarPeriod(e.value)
            setIpPhonesPeriod(e.value)
            setTierPeriod(e.value)
          }}
        />
      </div>

      <div className={style.position}>
        <Checkbox blue={true} checked={studentsDasboards} disabled={checkboxesAvaliable.disable} onChange={() => {
          setStudentsDashboards(!studentsDasboards)
        }}>
          <span className={style.checkboxText}>
            {checkboxesAvaliable.checked ? 'Личные кабинеты клиентов' : 'Личные кабинеты клиентов(недоступны)'}
          </span>
        </Checkbox>
        {(studentsDasboards && !checkboxesAvaliable.disable) && <Select value={findPeriod(periodOptions, studentsDasboardsPeriod)} options={periodOptions} onChange={(e) => {
          setStudentsDashboarPeriod(e.value)
        }}/>}
      </div>

      <div className={style.position}>
        <Checkbox blue={true} checked={ipPhones} disabled={checkboxesAvaliable.disable} onChange={() => {
          setIpPhones(!ipPhones)
        }}>
          <span className={style.checkboxText}>
            {checkboxesAvaliable.checked ? 'IP-телефония' : 'IP-телефония(недоступна)'}
          </span>
        </Checkbox>
        {(ipPhones && !checkboxesAvaliable.disable) && <Select value={findPeriod(periodOptions, ipPhonesPeriod)} options={periodOptions} onChange={(e) => {setIpPhonesPeriod(e.value)}} />}
      </div>


      <div className={style.summaryWrapper}>
        <div className={style.summary}>
          <div><strong>Итого</strong> за месяц</div>
          <div>{monthPay().toFixed(2)} ₽</div>
        </div>
        <div className={style.summary}>
          <div><strong>Всего</strong> к оплате</div>
          <div>
            <span className={style.accent}>{summary()} ₽</span>
          </div>
        </div>
        <div className={style.summary}>
          <div>Вы <strong>сэкономите</strong></div>
          <div>{discount()} ₽</div>
        </div>
      </div>
      <a onClick={() => openOverlay("pricing", "hero", "trial")} className={style.notaccount}>У меня еще нет аккаунта</a>
      <button className={style.send} onClick={() => {onClick()}}>Купить</button>
    </BaseOverlayComponent>
  )
}